import React from 'react'
import { printCurrency } from '../../../lib/utils'
import { Row, Col } from '../../generic/grid'
import { Text } from '../../generic'
import { FuneralItemCardContainer } from './FuneralItemCard.style'


function FuneralItemCard({ title, premiumAmount, onClick }) {
  return <FuneralItemCardContainer onClick={onClick}>
    <Row pad="8px" style={{ marginTop: "8px" }}>
      <Col grow>
        <Row>
          <Text primary bold size={14}>{title}</Text>
        </Row>
        <Row>
          <Text size={10} color="grey2">Monthly Contribution</Text>
        </Row>
      </Col>
      <Col alignVertical="flex-end" alignHorizontal="flex-end">
        <Text size={16} bold>{printCurrency(premiumAmount)}</Text>
      </Col>
    </Row>
  </FuneralItemCardContainer>
}

export default FuneralItemCard
